var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "month-building-range" },
    [
      _c("WaterEnergyTitle", {
        attrs: { title: "当月租户公区各楼栋耗电量占比" },
      }),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "detail-container" }, [
      _c("div", { staticClass: "common-title" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/images/rdata/building_propertion_tenant.png"),
            alt: "",
          },
        }),
        _c("span", { staticClass: "title-label" }, [_vm._v(" 租户 ")]),
      ]),
      _c("div", {
        staticClass: "chart-container",
        attrs: { id: "buildPropertionTenant" },
      }),
      _c("div", { staticClass: "common-title" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/images/rdata/building_propertion_common.png"),
            alt: "",
          },
        }),
        _c("span", { staticClass: "title-label" }, [_vm._v(" 租户 ")]),
      ]),
      _c("div", {
        staticClass: "chart-container",
        attrs: { id: "buildPropertionCommon" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }