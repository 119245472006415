var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "park-building-electric" },
    [
      _c("WaterEnergyTitle", { attrs: { title: "园区楼栋内耗电量概况" } }),
      _c(
        "div",
        { staticClass: "detail-container" },
        _vm._l(_vm.dataList, function (item, index) {
          return _c("div", { key: index, staticClass: "single-item" }, [
            _c(
              "div",
              {
                staticClass: "value-line",
                style: {
                  color: item.color,
                },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm._f("addCommas")(item.value)) +
                    "\n      "
                ),
              ]
            ),
            _c("div", { staticClass: "label-line" }, [
              _vm._v("\n        " + _vm._s(item.label) + "\n      "),
            ]),
          ])
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }