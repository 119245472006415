var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dynamic-building-electric" },
    [
      _c("WaterEnergyTitle", {
        attrs: { title: "10栋当月楼栋内每日耗电量变化趋势(单位:度)" },
      }),
      _c("div", {
        staticClass: "chart-container",
        attrs: { id: "dynamicBuildingChart" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }