<template>
  <div class="month-building-range">
    <WaterEnergyTitle title="当月租户公区各楼栋耗电量占比" />
    <div class="detail-container">
      <div class="common-title">
        <img
          src="@/assets/images/rdata/building_propertion_tenant.png"
          alt=""
        />
        <span class="title-label"> 租户 </span>
      </div>
      <div class="chart-container" id="buildPropertionTenant"></div>
      <div class="common-title">
        <img
          src="@/assets/images/rdata/building_propertion_common.png"
          alt=""
        />
        <span class="title-label"> 租户 </span>
      </div>
      <div class="chart-container" id="buildPropertionCommon"></div>
    </div>
  </div>
</template>

<script>
import WaterEnergyTitle from "@/components/WaterEnergyTitle";
import * as echarts from "echarts";
export default {
  name: "monthAvgElectric",
  components: {
    WaterEnergyTitle,
  },
  data() {
    return {
      myChart1: null,
      myChart2: null,
    };
  },
  mounted() {
    this.initTenantCharts();
    this.initCommonCharts();
  },
  methods: {
    initTenantCharts(datas) {
      const chartDom = document.getElementById("buildPropertionTenant");
      this.myChart1 = echarts.init(chartDom);
      let option = {
        color: [
          "#4b1aa6",
          "#3d39df",
          "#396bdf",
          "#3298e0",
          "#48b8d0",
          "#56cec7",
          "#87cf99",
          "#7949cf",
          "#b169de",
          "#8b62d1",
        ],
        grid: {
          left: "0",
          right: "0",
          bottom: "0",
          top: "0",
        },
        tooltip: {
          trigger: "item",
          padding: 10,
          borderWidth: 0,
          backgroundColor: "#00000099",
          textStyle: {
            color: "#152C5B",
          },
          formatter(params) {
            // let domStr = "<div class='inspection-tips-main'>";
            // domStr += `<span class="tips-circle" style="background: ${params.color}"></span><span>${params.name}</span>`;
            // domStr += "</div>";
            return domStr;
          },
        },
        series: [
          {
            type: "pie",
            radius: [20, 80],
            center: ["50%", "50%"],
            // roseType: "area",
            // roseType: "radius",
            itemStyle: {
              borderRadius: 8,
            },
            label: {
              show: true,
              color: "#152C5B",
              fontSize: 12,
            },
            data: datas || [
              {
                value: 40,
                name: "一期客服楼宇(7,10栋)\n比例:36%",
              },
              {
                value: 38,
                name: "一期客服楼宇(7,11栋)\n比例:36%",
              },
              {
                value: 32,
                name: "一期客服\n(8,10,11栋)-36%",
              },
            ],
          },
        ],
      };
      this.myChart1.setOption(option, false);
    },
    initCommonCharts(datas) {
      const chartDom = document.getElementById("buildPropertionCommon");
      this.myChart2 = echarts.init(chartDom);
      let option = {
        color: [
          "#4b1aa6",
          "#3d39df",
          "#396bdf",
          "#3298e0",
          "#48b8d0",
          "#56cec7",
          "#87cf99",
          "#7949cf",
          "#b169de",
          "#8b62d1",
        ],
        grid: {
          left: "0",
          right: "0",
          bottom: "0",
          top: "0",
        },
        tooltip: {
          trigger: "item",
          padding: 10,
          borderWidth: 0,
          backgroundColor: "#00000099",
          textStyle: {
            color: "#152C5B",
          },
          formatter(params) {
            // let domStr = "<div class='inspection-tips-main'>";
            // domStr += `<span class="tips-circle" style="background: ${params.color}"></span><span>${params.name}</span>`;
            // domStr += "</div>";
            return domStr;
          },
        },
        series: [
          {
            type: "pie",
            radius: [20, 80],
            center: ["50%", "50%"],
            // roseType: "area",
            // roseType: "radius",
            itemStyle: {
              borderRadius: 8,
            },
            label: {
              show: true,
              color: "#152C5B",
              fontSize: 12,
            },
            data: datas || [
              {
                value: 40,
                name: "一期客服楼宇(7,10栋)\n比例:36%",
              },
              {
                value: 38,
                name: "一期客服楼宇(7,11栋)\n比例:36%",
              },
              {
                value: 32,
                name: "一期客服\n(8,10,11栋)-36%",
              },
            ],
          },
        ],
      };
      this.myChart2.setOption(option, false);
    },
  },
};
</script>

<style lang="less" scoped>
.month-building-range {
  height: 570px;
  background: #ffffff;
  border-radius: 10px;
  padding: 14px 20px;
  .common-title {
    display: flex;
    margin-top: 26px;
    .title-label {
      font-size: 14px;
      font-weight: bold;
      color: #152c5b;
      line-height: 16px;
    }
    img {
      height: 14px;
      width: 14px;
      margin-right: 6px;
    }
  }
  .chart-container {
    height: 210px;
    width: 100%;
    margin-top: 10px;
  }
}
</style>