var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "month-avg-electric" },
    [
      _c("WaterEnergyTitle", { attrs: { title: "当月峰平谷耗电量占比" } }),
      _c("div", {
        staticClass: "chart-container",
        attrs: { id: "waterAvgElectric" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }