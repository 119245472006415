<template>
  <div class="main-param-overview">
    <WaterEnergyTitle title="变配电主要参数总览" />
    <div class="detail-container">
      <div class="single-item" v-for="(item, index) in dataList" :key="index">
        <div
          class="value-line"
          :style="{
            color: item.color,
          }"
        >
          <span class="value-span">
            {{ item.value | addCommas }}
          </span>
          <span class="unit-span">
            {{ item.unit }}
          </span>
        </div>
        <div class="label-line">
          {{ item.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WaterEnergyTitle from "@/components/WaterEnergyTitle";
export default {
  name: "mainParamOverview",
  components: {
    WaterEnergyTitle,
  },
  data() {
    return {
      dataList: [
        {
          label: "额定电压",
          value: "10222",
          unit: "KV",
          color: "#2A61FF",
        },
        {
          label: "额定电流",
          value: "22",
          unit: "A",
          color: "#152C5B",
        },
        {
          label: "损耗",
          value: "102",
          unit: "kw",
          color: "#152C5B",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.main-param-overview {
  height: 125px;
  background: #ffffff;
  border-radius: 10px;
  padding: 14px 20px;
  margin-top: 20px;
  .detail-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 20px;
    .single-item {
      flex: 1;
      text-align: center;
      .value-line {
        font-size: 28px;
        margin-bottom: 8px;
        .value-span {
          font-weight: bold;
          display: inline-block;
        }
        .unit-span {
          font-size: 0.5em;
        }
      }
      .label-line {
        font-size: 14px;
        font-weight: 400;
        color: #8190ac;
        line-height: 14px;
      }
    }
  }
}
</style>