<template>
  <div class="app-container electric-energy-consumption">
    <div class="search-line">
      <WaterEnergySearch />
    </div>
    <div class="card-container">
      <el-row :gutter="30">
        <el-col :span="12">
          <!-- 当月园区耗电量总览 -->
          <MonthElectricCount />
        </el-col>
        <el-col :span="12">
          <!-- 本年园区累计耗电量总览 -->
          <YearElectricCount />
        </el-col>
      </el-row>
    </div>
    <div class="card-container">
      <el-row :gutter="30">
        <el-col :span="16">
          <!-- 当月每日总耗电量变化趋势(单位:度) -->
          <MonthElectricTrend />
        </el-col>
        <el-col :span="8">
          <div>
            <!-- 园区楼栋内耗电量概况 -->
            <ParkBuildingElectricCount />
          </div>
          <div>
            <!-- 变配电主要参数总览 -->
            <MainParamOverview />
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="card-container">
      <el-row :gutter="30">
        <el-col :span="16">
          <el-row :gutter="30">
            <el-col :span="12">
              <!-- 当月峰平谷耗电量占比 -->
              <MonthAvgElectric />
            </el-col>
            <el-col :span="12">
              <!-- 当月租户公区耗电量占比 -->
              <MonthTenantElectric />
            </el-col>
          </el-row>
          <el-row>
            <!-- 当月各楼栋耗电量排名 -->
            <MonthBuildingRange />
          </el-row>
        </el-col>
        <el-col :span="8">
          <!-- 当月租户公区各楼栋耗电量占比 -->
          <MonthTenantBuildingPropertion />
        </el-col>
      </el-row>
    </div>
    <div class="card-container">
      <!-- 10栋当月楼栋内每日耗电量变化趋势(单位:度) -->
      <DynamicBuildingElectric />
    </div>
  </div>
</template>

<script>
import WaterEnergySearch from "@/components/WaterEnergySearch";
import MonthElectricCount from "./components/monthElectricCount.vue";
import YearElectricCount from "./components/yearElectricCount.vue";
import MonthElectricTrend from "./components/monthElectricTrend.vue";
import ParkBuildingElectricCount from "./components/parkBuildingElectricCount.vue";
import MainParamOverview from "./components/mainParamOverview.vue";
import MonthAvgElectric from "./components/monthAvgElectric.vue";
import MonthTenantElectric from "./components/monthTenantElectric.vue";
import MonthBuildingRange from "./components/monthBuildingRange.vue";
import MonthTenantBuildingPropertion from "./components/monthTenantBuildingPropertion.vue";
import DynamicBuildingElectric from "./components/dynamicBuildingElectric.vue";
export default {
  name: "electricEnergyConsumption",
  components: {
    WaterEnergySearch,
    MonthElectricCount,
    YearElectricCount,
    MonthElectricTrend,
    ParkBuildingElectricCount,
    MainParamOverview,
    MonthAvgElectric,
    MonthTenantElectric,
    MonthBuildingRange,
    MonthTenantBuildingPropertion,
    DynamicBuildingElectric,
  },
};
</script>

<style lang="less" scoped>
.electric-energy-consumption {
  margin: 10px 20px 20px 40px;
  background: none;
  padding-left: 0;
  .card-container {
    margin-top: 20px;
  }
}
</style>