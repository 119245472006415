<template>
  <div class="dynamic-building-electric">
    <WaterEnergyTitle title="10栋当月楼栋内每日耗电量变化趋势(单位:度)" />
    <div class="chart-container" id="dynamicBuildingChart"></div>
  </div>
</template>

<script>
import WaterEnergyTitle from "@/components/WaterEnergyTitle";
import * as echarts from "echarts";
export default {
  name: "monthAvgElectric",
  components: {
    WaterEnergyTitle,
  },
  mounted() {
    this.initCharts();
  },
  methods: {
    initCharts(dateList, completeDatas) {
      const chartDom = document.getElementById("dynamicBuildingChart");
      this.myChart = echarts.init(chartDom);
      let option = {
        grid: {
          left: "50px",
          right: "30px",
          bottom: "20px",
          top: "20px",
        },
        tooltip: {
          show: true,
          trigger: "axis",
          padding: 0,
          borderWidth: 0,
          backgroundColor: "#00000000",
          axisPointer: {
            type: "line",
            lineStyle: {
              width: 20,
              type: "solid",
              opacity: 0.5,
              color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                {
                  offset: 0,
                  color: "#0089FF",
                },
                {
                  offset: 1,
                  color: "#00000000",
                },
              ]),
            },
          },
          alwaysShowContent: false,
          formatter(params) {
            let domStr = "<div class='tips-main'>";
            params.forEach((item) => {
              const { seriesName, value } = item;
              const seriesNameClass =
                seriesName === "报单数" ? "tips-circle-bds" : "tips-circle-wcs";
              domStr += `<div class="tips-container"><span class="${seriesNameClass}"></span><span class="tips-detail">${seriesName}: ${value}</span></div>`;
            });
            domStr += "</div>";
            return domStr;
          },
        },
        xAxis: {
          boundaryGap: false,
          type: "category",
          data: dateList || ["9-1", "9-2", "9-3", "9-4", "9-5", "9-6", "9-7"],
          splitLine: {
            show: true,
            lineStyle: {
              color: "#0089FF20",
            },
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              color: "#0089FF20",
            },
          },
        },
        series: [
          {
            name: "进水量",
            type: "line",
            smooth: true,
            color: "#00FEFF",
            lineStyle: {
              width: 2,
              color: "#00FEFF",
            },
            label: {
              show: false,
            },
            showSymbol: false,
            areaStyle: {
              opacity: 1,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#00FEFF",
                },
                {
                  offset: 1,
                  color: "#00000000",
                },
              ]),
            },
            data: completeDatas || [50, 140, 4, 101, 222, 90, 111],
          },
        ],
      };
      this.myChart.setOption(option, false);
    },
  },
};
</script>

<style lang="less" scoped>
.dynamic-building-electric {
  height: 270px;
  background: #ffffff;
  border-radius: 10px;
  padding: 14px 20px;
  .chart-container {
    height: 210px;
    width: 100%;
    margin-top: 10px;
  }
}
</style>